.boxes {
    max-width: 1800px;
    margin: auto;
    h3 {
        font-size: clamp(1.2rem, 1.35vw, 2.1rem);
    }
    p {
        font-size: clamp(.9rem, .95vw, 1.35rem); 
    }
    .box {
        &:nth-child(1) {
            background-color: $black;
        }
        &:nth-child(2) {
            background-color: $red;
        }
        &:nth-child(3) {
            background-color: white;
            color: $blackText;
        }
    }
}