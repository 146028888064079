* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: rgb(46, 46, 46); 
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(12, 12, 12); 
}

::-webkit-resizer {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill {
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
::-webkit-resizer {
  display: none;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');