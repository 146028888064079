.personalDataContainer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999 !important;
  position: fixed;
}

.personalData {
  top: 50%;
  transform: translateY(-50%);
  width: 1400px;
  height: 28rem;
  overflow-y: auto;
  @media (max-width: 1600px) {
    width: 1200px;
    height: 25rem;
  }
  @media (max-width: 1400px) {
    width: 85vw;
  }
  @media (max-width: 1200px) {
    height: 27rem;
  }
  @media (max-width: 700px) {
    width: 90vw;
    height: 25rem;
  }
  @media (max-width: 850px) {
      top: 60% !important;
      transform: translateY(-60%);
  }
  @media (max-width: 550px) {
      top: 70% !important;
      transform: translateY(-70%);
  }
  left: 0;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: clamp(.9rem, .9vw, 1.35rem);
  margin: auto;
  z-index: 9999 !important;
  .spansForm {
    transition: all 500ms;

    &:hover {
      transform: scale(1.1);
    }

    span {
      background-color: $black;

      &:first-child {
        transform: rotate(45deg) translate(5px, 6.5px);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
  h1 {
    font-size: clamp(1.35rem, 1.7vw, 2.2rem);
    border-bottom: 2px solid $red;
    width: fit-content !important;
    display: inline;
    }
    p {
            color: $black;
            font-weight: 500;
            font-size: clamp(.9rem, .9vw, 1.2rem);
    }
}