.drop-container{
  &:hover{
    background-color: #fcf1f1;

  }
  border: 0.15rem solid $red;
  margin: 10px 0;
  width: 100%;
  transition: all 500ms;
  .dnd-input{
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    @media (max-width: 520px) {
      flex-direction: column;
      justify-content: center;
    }
  }
}
.dropzone-text{
  color: #2D2D2D;
  font-size: 1rem;
  display: inline-block;
  white-space: normal;
  text-align: center;
}
.images-wrapper{
  @media (max-width: 520px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .viewImage{
      max-width: 150px;
    }
  }
}
.viewImage{
  position: relative;
  margin: 0.5rem;
  img{
    width: 120px;
    height: 100px;
  }
}
.deleteIcon{
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:  0px 0px 0px 15px / 0px 0px 0px 15px;;
  background-color: $red;
  color: white;
  cursor: pointer;
  font-size: 15px;

}
.moveRight{
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  height: 30px;
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:  50%  0 0 50%;
  background-color: $red;
  color: white;
  cursor: pointer;
  font-weight: 800;
  font-size: 15px;
  @media (max-width: 520px){
    left: calc(50% - 15px) ;
    bottom: 0;
    top: unset;
    right: unset;
    transform: rotate(90deg);
  }
}
.moveLeft{
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 30px;
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:  50%  0 0 50%;
  background-color: $red;
  color: white;
  cursor: pointer;
  font-weight: 800;
  font-size: 15px;
  @media (max-width: 520px){
    left: calc(50% - 15px) ;
    top: 0;
    transform: rotate(90deg);
    border-radius:  0  50% 50% 0;
    padding-left: 0;
    padding-right: 5px;
  }
}