.gdpr {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    color: $darkBlack;
    &-wrapper { 
        h1 {
            font-size: clamp(1.5rem, 2.4vw, 3rem);
            border-bottom: 4px solid $red;
            width: fit-content !important;
            display: inline;
        }
        p, .text {
            color: $darkBlack;
            font-size: clamp(1rem, .9vw, 1.4rem);
        }
        h3 {
            font-size: clamp(1.1rem, 1.4vw, 1.95rem);
            border-bottom: 3px solid $red;
            width: fit-content !important;
            display: inline;
        }
        h6 {
            font-size: clamp(1rem, 1vw, 1.4rem);
        }
    }
    li {
        list-style-type: disc;
        list-style-position: inside;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}