.header {
    font-size: clamp(1rem, 1.2vw, 1.6rem);
    width: 100vw;
    transition: all 500ms;
    * {
        transition: all 500ms;
    }
    &__container {
        max-width: 1800px !important;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .red {
        color: $red;
        font-size: clamp(.85rem, .85vw, 1.2rem);
    }
    .spans {
        span {
            transition: all 500ms;
        }
    }
    .activeLink {
        color: red;
    }
    .activeSpans {
        span {
            transition: all 500ms;
            position: relative;
            &:nth-child(1) {
                transform: rotate(-45deg);
                top: 0.75rem;
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: rotate(45deg);
            }
        }
    }
    li {
        font-size: clamp(.95rem, 1.05vw, 1.3rem);
        display: flex;
        align-items: center;
        flex-direction: column;
        font-family: 'Raleway', sans-serif;
    }
    .line {
        height: 0.1rem;
        background-color: $red;
    }
    .cart {
        position: relative;
        &:after {
            content: attr(data-content);
            background: $red;
            position: absolute;
            color: white;
            padding: 0.1rem 0.55rem;
            display: inline-block;
            font-weight: bold;
            font-size: clamp(0.8rem, 0.9vw, 1.2rem);
            width: fit-content;
            border-radius: 100%;
            right: -.8rem;
            bottom: -.9rem;
        }
        @media (min-width: 2000px) {
            img {
                width: 2.5rem !important;
            }
            &::after {
                padding: 0.1rem 0.7rem !important;
            }
        }
        @media (max-width: 1750px) {
            &::after {
                bottom: -.5rem !important;
            }
        }
        @media (max-width: 1500px) {
            &::after {
                bottom: -.6rem !important;
                right: -.8rem !important;
                padding: 0.2rem 0.6rem !important;
            }
        }
    }
}

.mobile__menu {
    background-color: $black;
    transform: translateX(150%);
    li {
        font-size: clamp(1.5rem, 1.7vw, 2.1rem) !important;
        font-weight: 600;
        text-align: center !important;
        margin-left: 2rem;
        margin-right: 2rem;
        &:hover {
            border: none;
        }
        a {
            text-align: center;
            position: relative;
        }
    }
}

.header-fixed {
    background: rgb(32, 32, 32);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}