.services {
    width: 100vw;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    height: fit-content !important;
    h1 {
        font-size: clamp(1.8rem, 2.25vw, 3.5rem);
        color: $black;
    }
    .line {
        background-color: $red;
    }
    .item {
        background-color: $black;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transition: all 500ms;
        justify-content: space-between !important;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -19px 30px, rgba(0, 0, 0, 0.12) 0px 8px 6px, rgba(0, 0, 0, 0.17) 0px 16px 13px, rgba(0, 0, 0, 0.09) 0px -9px 5px;
        }
        h1 {
            color: white;
            font-size: clamp(1.2rem, 1.8vw, 2.35rem);
            font-family: 'Raleway';
            font-weight: 600;
        }
        h2 {
            font-size: clamp(1.1rem, 1.6vw, 2.15rem);
            font-family: 'Raleway';
            font-weight: 600;
        }
        li {
            font-size: clamp(.85rem, .9vw, 1.2rem); 
        }
        button {
            background-color: $red;
            transition: all 500ms;
            span {
                font-family: 'Raleway', sans-serif;
                font-size: clamp(.85rem, .9vw, 1.2rem); 
                letter-spacing: 0.05rem;
                line-height: 1;
            }
            &:hover {
                background-color: $redHover;
            }
        }
    }
}