.nutricni-anamneza {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    color: $darkBlack;
    * {
        ::-webkit-scrollbar {
            width: 0px;
            height: 0;
        }
    }
    &-wrapper {
        form {
            width: 1400px;
            @media (max-width: 1550px) {
                width: 90vw;
            }
            input[type=number] {
                -moz-appearance: textfield;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input, textarea, select {
                outline: none;
                border: 3px solid rgb(235, 234, 234);
                border-radius: 0.75rem;
                transition: all 500ms;
                &:focus {
                    border: 3px solid rgb(190, 188, 188);
                }
            }
            .form-container {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 0.75rem;
            }
        }
        h1 {
            font-size: clamp(1.5rem, 2.4vw, 3rem);
            border-bottom: 4px solid $red;
            width: fit-content !important;
            display: inline;
        }
        p, span, select, option {
            color: $black;
            font-weight: 500;
            font-size: clamp(.9rem, .9vw, 1.2rem);
        }
        .small-span {
            color: rgb(177, 177, 177);
            font-size: clamp(.9rem, .85vw, 1.2rem);
            font-weight: 400;
        }
        h2 {
            font-size: clamp(1.1rem, 1.4vw, 1.95rem);
            width: fit-content;
            font-weight: 600;
        }
        h3 {
            font-size: clamp(0.95rem, 1.1vw, 1.5rem);
            width: fit-content;
            font-weight: 600;
        }
        h6 {
            color: rgb(139, 138, 138);
            font-size: clamp(.9rem, .85vw, 1.2rem);
            font-weight: 400;
            width: fit-content;
        }
        .line {
            background-color: $red;
            display: block;
        }
        .box {
            background-color: rgb(253, 253, 253);
            border-radius: 0.75rem;
            border: 3px solid rgb(235, 234, 234);
        }
        .table {
            overflow-x: hidden;
            width: 80vw;
            max-width: 80vw !important;
            @media (min-width: 1150px) {
                width: 100%;
            }
        }
        table {
            width: 100%;
            max-width: 100%;
            tr {
                width: 100%;
                text-align: center;
                th, td {
                    padding-top: 0.25rem;
                    padding-bottom: 0.25rem;
                    font-size: clamp(.9rem, .9vw, 1.2rem);
                }
                th {
                    border-bottom: 1.5px solid black;
                    &:nth-child(2) {
                        border-left: 1.5px solid black;
                        border-right: 1.5px solid black;
                    }
                }
                td {
                    border-bottom: 1px solid black;
                    &:nth-child(2) {
                        border-left: 1.5px solid black;
                        border-right: 1.5px solid black;
                    }
                }
                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
        input[type=submit] {
            width: fit-content;
            background-color: $red;
            border: none;
            color: white;
            margin-left: auto;
            margin-right: auto;
            border-radius: 0.75rem;
            font-size: clamp(.9rem, .9vw, 1.2rem);
            transition: all 500ms;
            &:hover {
                background-color: $redHover;
            }
        }
        .souhlas {
            transition: all 500ms;
            border-bottom: 1px solid transparent;
            &:hover {
                border-bottom: 1px solid black;
            }
        }
    }
    .err {
        color: $red;
        font-size: clamp(.8rem, .8vw, 1.1rem);
    }
}