.uvodka {
    height: 100vh;
    max-height: 60rem;
    @media (max-width: 1200px) {
        height: 40rem !important;
    }
    @media (max-width: 900px) {
        background-position: center center;
    }
    @media (max-width: 600px) {
        height: 35rem !important;
    }
    background-image: url('../../images/uvodka.png');
    background-repeat: no-repeat;
    background-position: center 30%;
    background-size: cover;
    image-rendering: -webkit-optimize-contrast;
    width: 100vw !important;
    overflow-x: hidden;
    .container {
        max-width: 1800px;
        margin: auto;
        height: 100%;;
    }
    h1 {
        font-size: clamp(2.3rem, 4.25vw, 6.5rem);
    }
    p {
        font-size: clamp(1.1rem, 1.3vw, 1.8rem);
    }
    button {
        font-size: clamp(1.15rem, 1.1vw, 1.5rem);
        transition: all 500ms;
        &:hover {
            border: 2px solid $black;
            color: white !important;
        }
    }
    .btn-bg {
        background-color: $black;
    }
    .socials {
        li {
            transition: all 500ms;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.main {
    background-color: $black;
}
