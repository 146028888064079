$red: #D71A20;
$lightRed: #be4747;
$redHover: #990b10;
$black: #1d1f1f;
$footer: #1e1f1f;
$darkBlack: rgb(19, 18, 18);
$blackText: #121313;
$white: #FAF8F8;
$lightGray: rgb(231, 231, 231);
$green: rgb(32, 122, 32);
$greenHover: rgb(22, 100, 22);