.notFoundContainer {
    max-width: 1800px;
    margin: auto;
    min-height: 50rem;
    display: flex;
    justify-items: center;
    align-items: center;
    color: $black;
    .notFound {
        &-wrapper { 
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: fit-content;
        .blue {
            color: $black;
        }
        &-error {
            font-size: clamp(2rem, 2.7vw, 4.2rem);
            color: $black;
        }
        color: $blackText;
        font-size: clamp(1.2rem, 1.1vw, 2.2rem);
        button {
            background-color: $red;
            transition: all 500ms;
            font-size: clamp(.9rem, .95vw, 1.5rem);
            &:hover {
                background-color: $redHover;
            }
        }
    }
    }
}