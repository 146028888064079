.about {
    @media (min-width: 1024px) {
        -webkit-clip-path: polygon(48% 7%, 85% 2%, 100% 0, 100% 70%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 18%, 18% 14%);
        clip-path: polygon(48% 7%, 85% 2%, 100% 0, 100% 70%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 18%, 18% 14%);
    }
    width: 100vw;
    background-image: url("../../images/about.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 100%;
    z-index: 1 !important;
    h1 {
        font-size: clamp(1.8rem, 2.25vw, 3.5rem); 
    }
    p {
        font-size: clamp(.9rem, .95vw, 1.35rem); 
    }
    .line {
        background-color: $red;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width : 100%;
        height: 100% !important;
        background: inherit;
        z-index: -1 !important;
        filter        : brightness(35%);
        -moz-filter   : brightness(35%);
        -webkit-filter: brightness(35%);
        -o-filter     : brightness(35%);
        transition        : all 2s linear;
        -moz-transition   : all 2s linear;
        -webkit-transition: all 2s linear;
        -o-transition     : all 2s linear;
        }
}