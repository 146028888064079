.kosik {
    h1 {
        color: $blackText;
        text-align: center;
        font-size: clamp(1.5rem, 1.8vw, 2.7rem);
        border-bottom: 2px solid $red;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
    h4 {
        color: $blackText;
        text-align: center;
        font-size: clamp(1rem, 1.1vw, 1.25rem);
        margin-left: auto;
        margin-right: auto;
    }
    &__mainDiv {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        transition: all 500ms;
        .form {
            background-color: $black;
            p {
                font-size: clamp(0.8rem, 0.85vw, 1.1rem);
            }
            input {
                background-color: transparent;
                border-bottom: 1px solid white;
                font-family: 'Raleway', sans-serif;
                font-size: clamp(.9rem, .95vw, 1.35rem);
                &::placeholder {
                    color: $lightGray;
                    font-family: 'Raleway', sans-serif;
                }
            }
            label {
                font-weight: 500;
                margin-bottom: 0.375em;
                font-size: clamp(.9rem, .95vw, 1.35rem);
                input {
                    left: -9999px;
                    font-size: clamp(.9rem, .95vw, 1.35rem);
                    &:checked + span {
                        background-color: $red;
                        color: white;
                        border: 2px solid $red;
                        &:before {
                            box-shadow: inset 0 0 0 0.25em #1d2329;
                        }
                    }
                }
                span {
                    padding: 0.375em 0.75em 0.375em 0.375em;
                    border-radius: 99em;
                    transition: 0.25s ease;
                    font-size: clamp(.9rem, .95vw, 1.35rem);
                    border: 2px solid white;
                    &:hover {
                        background-color: $lightRed;
                        border: 2px solid $lightRed;
                    }
                    &:before {
                        display: flex;
                        flex-shrink: 0;
                        content: "";
                        background-color: rgb(236, 231, 231);
                        width: 1.5em;
                        height: 1.5em;
                        border-radius: 50%;
                        margin-right: 0.375em;
                        transition: 0.25s ease;
                        box-shadow: inset 0 0 0 0.1em $red;
                    }
                }
            }
            .objednat_btn {
                transition: all 500ms;
                background-color: $green;
                &:hover {
                    background-color: $greenHover;
                }
            }
        }
    }
    .item {
        border-bottom: 1px solid $black;
        h1 {
            margin: unset;
            text-align: left;
            border: none;
            font-size: clamp(.95rem, 1.05vw, 1.25rem);
        }
        h2 {
            color: $blackText;
            font-size: clamp(.85rem, .95vw, 1.15rem);
        }
        span {
            background-color: $red;
            position: relative;
            &:first-child {
                transform: rotate(45deg);
                top: 0.2rem;
            }
            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }
    .prazdnyKosik {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        transition: all 500ms;
        h1 {
            font-size: clamp(1.3rem, 1.3vw, 2.3rem);
        }
        button {
            width: fit-content !important;
            font-size: clamp(.9rem, .95vw, 1.35rem) !important;
            background-color: $red;
            transition: all 500ms;
            &:hover {
                background-color: $redHover;
            }
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    textarea:-webkit-autofill {
        transition: all 5000s ease-in-out 0s;
        background-color: transparent;
        border-top:none;
        border-left:none;
        border-right:none;
        outline: none;
    }
    .totalPrice {
        color: $blackText;
        font-size: clamp(1rem, 1.1vw, 1.5rem);
    }
}

.quantitySpace{
    font-weight: 400;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.quantityAction{
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    color: white;
    background-color: #D71A20;
    margin-left: 3px;
    &:last-child {
        padding-left: 6px;
        padding-right: 6px;
    }
}
