.footer {
  width: 100%;
  background: $footer;
  transition: all 500ms;
  font-size: clamp(0.85rem, .9vw, 1.2rem);
}

.footer-container {
  margin: auto;
  max-width: 1800px;
  * {
    transition: all 300ms;
  }
  h1 {
    font-size: clamp(1.25rem, 1.2vw, 1.4rem);
    font-weight: 500;
    color: white;
    font-family: 'Raleway';
  }
  .odkazy-footer {
    li {
      &:hover {
        color: $red;
      }
    }
  }
  ul {
    padding-top: 0.35rem;
    line-height: 2.4rem;
    @media (max-width: 1280px) {
      line-height: 2rem;
    }
    @media (max-width: 1024px) {
      line-height: 1.75rem;
    }
    color: white;
  }
  .borderFlex {
    border-bottom: 2px solid $red;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    }
  .footer-form {
    button {
      border-radius: 5px;
      padding: 0.35rem 0;
      background-color: $red;
      &:hover {
        background-color: $redHover;
      }
    }
    input,
    textarea {
      border-radius: 5px;
      font-weight: 300;
      padding: 0.35rem 0 0.35rem 0.6rem;
      outline: none;
      border: 2px solid transparent;
      &:focus {
        border: 2px solid $red;
      }
      @media (max-width: 650px) {
        font-size: 0.95rem;
      }
    }
    input {
      &::placeholder {
        color: #afafaf;
      }
    }
    textarea {
      &::placeholder {
        padding-top: 0.2rem;
        color: #afafaf;
      }
    }
    .secondTextArea {
      min-height: 10rem;
    }
  }
  .err {
    width: 100%;
    font-size: clamp(0.8rem, 0.85vw, 1.1rem);
  }
  .socials {
        li {
            transition: all 500ms;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
.form-wrapper {
  row-gap: 0.35rem;
}
.second {
    background-color: $darkBlack;
    font-size: clamp(0.85rem, .95vw, 1.3rem);
}